@import "../../styleLibrary";

.agreementForm {
  max-width: 600px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto auto;
  padding: @default-padding;
  background-color: @white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.7);
  max-height: 95vh;
  overflow: auto;
}
.formField {
  width: 40vw;
  max-width: 450px;
  margin: @small-margin;
}
.error {
  color: @red;
  text-align: left;
  display: block;
  width: 100%;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.connectedCheckboxes {
  display: contents;
}

@primary-color: #1DA57A;